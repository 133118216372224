import { Component, Input } from '@angular/core';

@Component({
  selector: 'svg-icon-sprite',
  styles: [`:host svg,:host use{fill:currentColor}`],
  template: `<ng-container *ngIf="!viewBox && !attribute">
  <svg [attr.class]="classes"
       [attr.width]="width"
       [attr.height]="height || width">
    <use [attr.xlink:href]="src"></use>
  </svg>
</ng-container>

<ng-container *ngIf="!viewBox && attribute">
  <svg [attr.class]="classes"
       [attr.width]="width"
       [attr.height]="height || width"
       IconSpriteAttr [attribute]="attribute">
    <use [attr.xlink:href]="src"></use>
  </svg>
</ng-container>

<ng-container *ngIf="viewBox && !attribute">
    <svg [attr.class]="classes"
       [attr.width]="width"
       [attr.height]="height || width"
       [attr.viewBox]="viewBox"
       [attr.preserveAspectRatio]="preserveAspectRatio">
    <use [attr.xlink:href]="src"></use>
  </svg>
</ng-container>

<ng-container *ngIf="viewBox && attribute">
    <svg [attr.class]="classes"
       [attr.width]="width"
       [attr.height]="height || width"
       [attr.viewBox]="viewBox"
       [attr.preserveAspectRatio]="preserveAspectRatio"
       IconSpriteAttr [attribute]="attribute">
    <use [attr.xlink:href]="src"></use>
  </svg>
</ng-container>
`
})
export class IconSpriteComponent {

  @Input() public src: string;
  @Input() public classes = 'icon';
  @Input() public width = '100%';
  @Input() public height: string;
  @Input() public viewBox: string;
  @Input() public preserveAspectRatio = 'xMinYMax meet';
  @Input() public attribute: [string, string];

  constructor() { }
}
